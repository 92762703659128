import { Button } from '@keyliving/component-lib';
import { ReactNode } from 'react';
import type Swiper from 'swiper';
import { useSwiper } from 'swiper/react';

interface NextButtonProps {
    children?: ReactNode;
    onNext: (swiper: Swiper) => void;
}

export default function NextButton({ children, onNext }: NextButtonProps) {
    const swiper = useSwiper();

    return (
        <Button
            fill="outline"
            onClick={(e) => {
                e.stopPropagation();

                onNext(swiper);
            }}
            rounded
            size={{
                xs: 'sm',
                md: 'md',
            }}
            theme="tertiary"
            type="button"
            width="auto"
        >
            {children}
        </Button>
    );
}
