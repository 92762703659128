import '@keyliving/component-lib/dist/css/global.css';
import './scss/app.scss';

import { withProfiler, wrapUseRoutes } from '@sentry/react';
import { useRoutes } from 'react-router-dom';

import HydrationGate from './components/HydrationGate';
import Welcome from './components/Welcome';
import getRoutes from './routes/lib';

const useSentryRoutes = wrapUseRoutes(useRoutes);

function App() {
    const routes = useSentryRoutes(getRoutes());

    return (
        <HydrationGate>
            <>
                {routes}
                <Welcome />
            </>
        </HydrationGate>
    );
}

export default withProfiler(App);
