import { IconProps } from './types';

export default function CheckInCircleFilled({ height = 17, width = 17, ...rest }: IconProps) {
    return (
        <svg
            fill="none"
            height={height}
            viewBox="0 0 17 17"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M8.5 0A8.502 8.502 0 0 0 0 8.5C0 13.193 3.807 17 8.5 17S17 13.193 17 8.5 13.193 0 8.5 0Zm4.374 6.446L7.88 11.457a.902.902 0 0 1-.62.266.831.831 0 0 1-.62-.266L4.127 8.943a.877.877 0 0 1 0-1.258.877.877 0 0 1 1.257 0L7.26 9.562l4.374-4.374a.877.877 0 0 1 1.258 0 .893.893 0 0 1-.018 1.258Z"
                fill="currentColor"
            />
        </svg>
    );
}
