import { PRODUCT_TYPE } from './Product';

export enum INVOICE_STATUS {
    PENDING_PAYMENT = 'PENDING_PAYMENT',
    PAID = 'PAID',
    APPROVED_FOR_REMITTANCE = 'APPROVED_FOR_REMITTANCE',
    CANCELLED = 'CANCELLED',
    PAST_DUE = 'PAST_DUE',
    CHARGE_DISPUTE = 'CHARGE_DISPUTE',
    OTHER = 'OTHER',
    PAYMENT_FAILED = 'PAYMENT_FAILED',
    PAYMENT_SCHEDULED = 'PAYMENT_SCHEDULED',
}

export enum INVOICE_TYPE {
    MONTHLY = 'MONTHLY',
    PARTIAL_MONTH = 'PARTIAL_MONTH',
    TERMINATION = 'TERMINATION',
    INITIAL_INVESTMENT = 'INITIAL_INVESTMENT',
    OTHER = 'OTHER',
    OWNER_MIRROR = 'OWNER_MIRROR',
    DEPOSIT_AGREEMENT = 'DEPOSIT_AGREEMENT',
}

// This matches the structure in the DB
export interface BaseInvoice {
    due_date: string | null;
    email_sent: boolean;
    id: string;
    memo: string | null;
    reference_id: number;
    service_end_date: string | null;
    service_start_date: string | null;
    status: INVOICE_STATUS;
    subtotal: number;
    taxes: number;
    total: number;
    type: INVOICE_TYPE;
    user_id: string;
    building_suite_id: string | null;
    created_at: string | null;
    updated_at: string | null;
}

export interface InvoiceResident {
    id: string;
    email: string;
    phone_number: string | null;
    name: string | null;
}

export enum TAX_CODE {
    HST = 'HST',
    TAX_FREE = 'TAX_FREE',
    LTT = 'LTT',
}

export interface InvoiceLineItem {
    id: string;
    amount: number;
    tax_amount: number;
    type: PRODUCT_TYPE;
    description: string | null;
    product_taxable: boolean;
}

export interface InvoiceAsset {
    name: string;
    unit: string | null;
}

export interface Invoice extends BaseInvoice {
    created_at: string;
    updated_at: string;
    resident?: InvoiceResident;
    invoiceLineItems?: InvoiceLineItem[];
    buildingSuite?: InvoiceAsset;
}

export enum TransactionEventType {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
}

export interface TransactionEvent {
    id: string;
    transaction_id: string;
    type: TransactionEventType;
    api_response: string;
    created_at: string;
    updated_at: string;
}

export interface Transaction {
    id: string;
    vopay_transaction_id: string;
    account_id: string;
    invoice_id: string;
    amount: number;
    status: string;
    events: TransactionEvent[];
    created_at: string;
    updated_at: string;
}
