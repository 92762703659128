import { route } from 'react-router-typesafe-routes/dom';
import { zod } from 'react-router-typesafe-routes/zod';
import { string } from 'zod';

/**
 * NOTE: Validation helpers catch parsing and validation errors
 * and replace values with undefined.
 *
 * e.g. zod(string().email()) - Requires the email passed in
 * the url to be a valid email. Otherwise the value
 * returned by useTypedSearchParams for email
 * will be undefined
 */

export const HOME_SEARCH_URLS = {
    DepositAgreement: route('deposit-agreement'),
    Financial: route(
        'financial',
        {},
        {
            Update: route('update'),
        }
    ),
    Home: route(''),
    MyHomeSearch: route('home-search'),
    ScheduleDeposit: route(
        'schedule-deposit',
        {},
        {
            Success: route('success'),
        }
    ),
};

export const PORTAL_URLS = {
    Home: route(''),
};

// Can be accessed no matter what stage the user is at
export const URLS = {
    Login: route('login', {
        searchParams: {
            token: zod(string()),
            email: zod(string().email()),
        },
    }),
};
